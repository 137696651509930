import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});

export default class extends Controller {
  static targets = ["modal", "repoInput", "repos", "listItem", "event", "eventModal", "deployBtn", "eventsList", "pushToDeployBtn"]
  initialize(){
    if (this.hasReposTarget){
      fetch_retry("?repos=all")
        .then(response => response.text())
        .then(html => {
          this.reposTarget.innerHTML = html
        })
    }
  }
  onDeploySuccess(event){
    this.deployBtnTarget.innerHTML = `
      <div data-controller="content-loader"
    data-content-loader-url="../deploy-button"
    data-content-loader-refresh-interval="2000"
    data-action="deploy-ended->content-loader#stopRefreshing"
    class="flex w-full my-2 justify-end">
      <a class="no-underline btn btn-green-full p-4" disabled=True>Deploying site...</a>
      </div>
      `
  }
    onTogglePushToDeploySuccess(event){
      this.pushToDeployBtnTarget.innerHTML = event.detail[0]
    }
  onDeleteSuccess(event){
    Turbolinks.visit(event.detail[0].redirect_to)
  }

  onEventResultError(event){
  }
  onEventResultSuccess(event){
    this.modalTarget.innerHTML = event.detail[0]
  }
}
