import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "button" ]
  connect() {
    if (document.queryCommandSupported("copy")) {
      this.buttonTargets.forEach(function (button) {
        button.classList.remove("hidden")
      });
    }
  }
  copy() {
    this.sourceTarget.select()
    document.execCommand("copy")
  }
}
