import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});

export default class extends Controller {
  static targets = ["formContainer"]
  initialize(){
    fetch_retry("nginx-config")
      .then(response => response.text())
      .then(html => {
        this.formContainerTarget.innerHTML = html
      })
  }
}
