
import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});

export default class extends Controller {
  static targets = ["list", "databaseFormContainer", "databaseForm", "databaseName"]
  initialize(){
    this.getDatabaseList()
    this.renderForm()
  }
  getDatabaseList(){
    fetch_retry('databases')
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html
      })
  }
  renderForm(){
    fetch_retry('databases/new')
      .then(response => response.text())
      .then(html => {
        this.databaseFormContainerTarget.innerHTML = html
      })
  }
  onPostSuccess(event){
    let [data, status, xhr] = event.detail;
    this.listTarget.innerHTML += xhr.response
    this.renderForm()
  }
  onPostError(event){
    let [data, status, xhr] = event.detail;
    this.databaseFormContainerTarget.innerHTML = xhr.response
  }
}
