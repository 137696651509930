import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});
export default class extends Controller {
  static targets = ["createForm", "providerSelect"]

  initialize(){
  }
  updateForm(event){
    const provider = event.target.value
    if (event.target.value){
      fetch_retry('provider-form?provider=' + provider)
        .then(response => response.text())
        .then(html => {
          this.createFormTarget.innerHTML = html
        })
    }
  }
}
