import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});

export default class extends Controller {
  connect() {
    this.load()
    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetch_retry(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
      })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
