import { Controller } from "stimulus"

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});
export default class extends Controller {
  static targets = ["branchSelect", "detailsForm", "wsgiFile", "requirementsFile", "environmentVariables"]

  initialize(){
  }
  updateFiles(event){
    // when branch changes, update file lists
    const selected_index = this.branchSelectTarget.selectedIndex;
    const branch_sha = this.branchSelectTarget.options[selected_index].value
    fetch_retry('site-details-form?branch=' + branch_sha)
      .then(response => response.text())
      .then(html => {
        this.detailsFormTarget.innerHTML = html
      })
  }
}
